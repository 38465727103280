'use client';

import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Script from 'next/script';

import { ApolloProvider, IStoreMetadata, useAnalytics } from '@/data';
import { AuthProvider, DataProvider, SurfsideAnalytics } from '@/components';
import { SpecialsProvider } from '@/context';

const GOOGLE_MAP_API = process.env.NEXT_PUBLIC_GOOGLE_MAP_API;

const queryClient = new QueryClient();

export const Providers: FC<PropsWithChildren & { data?: IStoreMetadata }> = ({
  children,
  data,
}) => {
  const { initAnalytics } = useAnalytics();
  initAnalytics();

  return (
    <ApolloProvider pageProps={{}}>
      <SurfsideAnalytics />
      <QueryClientProvider client={queryClient}>
        <DataProvider data={data}>
          <AuthProvider>
            <SpecialsProvider>{children}</SpecialsProvider>
          </AuthProvider>
        </DataProvider>
        <ReactQueryDevtools initialIsOpen={false} />

        {/* load google map script */}
        <Script src={GOOGLE_MAP_API} strategy="beforeInteractive" />
      </QueryClientProvider>
    </ApolloProvider>
  );
};
